import { type Image } from './types.js';

interface ImageResizeParams {
    img: string | Image;
    size: { width?: number; height?: number };
    fill?: string;
}

export function getImageSrcSet(img: string | Image, sizes: number[]) {
    const srcs = sizes
        .map(size => {
            const url = getImageResized({
                img,
                size: { width: size },
            });

            return `${ url } ${ size }w`;
        })
        .join(',');

    return srcs;
}

export function getImageResized(params: ImageResizeParams) {
    if (!params.img) return '';

    let img = params.img;
    if (typeof img !== 'string') {
        img = img.url;
    }
    if(!img) return '';
    // there are sometimes commas in URL
    // this breaks srcset, so we need to escape them
    const escaped = img.replace(/,/g, '%2C');
    const url = new URL(escaped);

    if (params.size.width) {
        url.searchParams.set('w', params.size.width.toString());
    }

    if (params.size.height) {
        url.searchParams.set('h', params.size.height.toString());
    }

    return url.toString();
}
